@import '../../../styles/variables';

.playButton{
    position: absolute;

    svg{
        filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.4));

        path{
            transition: .2s;
        }
    }
    
}
@import '../../../styles/variables';

.sectionTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;


    >div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h3{
        font-size: 1.7rem;
        margin-top: 0;
        font-weight: 700!important;
        margin-bottom: 20px;
        font-family: 'Poppins'!important;
        
        @include mobile{
            font-size: 1.2em;
        }

        &.read-title-recent-posts{
            font-family: "Poppins"!important;
          }
    }

    .sectionTitle__divider{
        width: 68px;
        height: 3px;
        background-color: #F6BC41;       

        &.section-title-has-no-divider{
            width: 0;
            height: 0;
            border-color: black;
        }
    }   
}

.sectionTitle-white{
    color: white;
}
.sectionTitle-charcoal{
    color: color(charcoal);

    .sectionTitle__divider{
        background-color: color(charcoal);
    }
}
.sectionTitle-charcoalDark{
    color: color(charcoalDark);

    .sectionTitle__divider{
        background-color: color(charcoalDark);
    }
}

.sectionTitle-wMargin{
    margin-bottom: var(--titleBottomMargin);
}

.sectionTitle-left{
    justify-content: flex-start;
    
    >div{
        align-items: flex-start;
    }
}
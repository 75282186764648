@import '../../../styles/variables';

.simplePlayer{
    margin-right: 30px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    .simplePlayer__video{
        overflow: hidden;
        transition: .2s;
        position: relative;
        cursor: pointer;

        .simplePlayer__preview{
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            
            >img{
                width: 100%;
                object-fit: contain;
                
            }

        }

        >video{
            position: absolute;
            left: 0;
            max-width: 100%;
            transition: .2s;
            object-fit: cover;
        }
            
    }

    .simplePlayer__video:hover{
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, .2);

        path{
            fill-opacity: .9;
        }

    }

    .simplePlayer__player{
        position: absolute;
        top: 0;
        left: 0;
        transition: .5s;
        width: 100%;
        height: 100%;
    }

}

.simplePlayer:last-child{
    margin-right: 0;
}

.simplePlayer__video-hidden{
    .simplePlayer__player{
        opacity: 0;
    }

    video{
        display: none;
    }
}
.simplePlayer__video-showing{
    img{
        opacity: 0;
    }

    video{
        display: flex;
    }
}

.simplePlayer__play-hidden{
    opacity: 0;
}

.simplePlayer__preview-animate:after{
    animation: glowUp 5s ease-in-out;
    animation-fill-mode: forwards;  
    content: "";
    position: absolute;
    top: -200%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);    
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
        to right, 
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0.0) 100%
    );
}

@keyframes glowUp{
    10% {
      opacity: .4;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    40% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
  }

@import '../../../styles/variables';

.xoMedia{
    background-color: color(charcoalDark);
    padding: var(--mainSectionPadding);
    text-align: center;

    .xoMedia__container{
        display: flex;

        @include mobile{
            flex-direction: column;
        }
        
        >div{
            width: 100%;
            margin-right: 40px;

            @include mobile{
                margin-bottom: 40px;
            }
        }
        >div:last-child{
            margin-bottom: 0;
            margin-right: 0;
        }

        >div > a >div{
            position: relative;
            height: 35px;
            width: 100%;

            @include mobile{
                height: 28px;
            }
        }
    }
}

.carrousel{
  
    .carrousel__slide{
        height: 100px;
        
        a{
            >div{
                display: flex;
                width: 100%;
                justify-content: center;
                
                >div{
                    width: 70%;
                    height: 50px;
                    position: relative;
                }
            }
        }
    }
}